<script setup
        lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <mask id="mask0_661_1273" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="currentColor"/>
    </mask>
    <g mask="url(#mask0_661_1273)">
      <path d="M9.33333 13.8171V12.4504C10.3333 12.1615 11.1389 11.6059 11.75 10.7837C12.3611 9.9615 12.6667 9.02817 12.6667 7.98372C12.6667 6.93928 12.3611 6.00595 11.75 5.18372C11.1389 4.3615 10.3333 3.80595 9.33333 3.51706V2.15039C10.7111 2.4615 11.8333 3.15872 12.7 4.24206C13.5667 5.32539 14 6.57261 14 7.98372C14 9.39483 13.5667 10.6421 12.7 11.7254C11.8333 12.8087 10.7111 13.5059 9.33333 13.8171ZM2 10.0004V6.00039H4.66667L8 2.66706V13.3337L4.66667 10.0004H2ZM9.33333 10.6671V5.30039C9.85556 5.54484 10.2639 5.9115 10.5583 6.40039C10.8528 6.88928 11 7.42261 11 8.00039C11 8.56706 10.8528 9.09206 10.5583 9.57539C10.2639 10.0587 9.85556 10.4226 9.33333 10.6671ZM6.66667 5.90039L5.23333 7.33372H3.33333V8.66706H5.23333L6.66667 10.1004V5.90039Z" fill="currentColor"/>
    </g>
  </svg>
</template>

<style scoped>

</style>